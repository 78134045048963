import { css, jsx } from '@emotion/react'
import { useColorMode } from '@chakra-ui/react'
import { rgba } from 'polished'
import { useMemo } from 'react'
import { Box } from '../chakra/Box'
import theme from '../../constants/theme'
import Div from '../Div'
import { Text } from '../chakra/Text'
import VerticalSpace from '../VerticalSpace'
import useResponsive from '../../utils/hooks/useResponsive'
import { Button } from '../chakra/Button'
import { Check } from '../chakra/icons/Check'
import { billings } from '../../constants'
import { useIsChrome } from '../../dataHooks'

const list = [
  {
    id: 1,
    favourite: false,
    title: billings.starter.name,
    price: billings.starter.price,
    priceDescription: 'per month',
    priceClarification: '',
    features: [
      'Unlimited bookmarks',
      'Unlimited collections',
      'Unlimited devices',
      'Nested collections',
      'Organize with tags',
      'Bookmark thumbnails',
      'Add custom notes',
      'Search and sorting',
      'Batch, move, delete and add tags',
      'All essential features',
    ],
    next: [],
    button: {
      label: 'Start for free',
      to: '/login',
    },
  },
  {
    id: 2,
    favourite: true,
    title: billings.premiumYearly.name,
    price: billings.premiumYearly.price,
    priceDescription: 'per year',
    features: [
      'Everything from the free plan',
      'Import & export bookmarks',
      'Mobile support',
      'Link to web archive',
      'Open all in a new window',
      'Share your bookmarks',
      'Personalized icons',
      'Chrome desktop app',
      'Mobile shortcut',
      'Permanent copies',
      'Automatic tagging',
      'Reading time',
      'Full-text search',
      'Bookmark previews',
      'Personalized Support',
    ],
    next: [],
    button: {
      label: `Get ${billings.premiumYearly.name}`,
      to: '/pricing',
    },
  },
  {
    id: 3,
    favourite: false,
    title: billings.premiumMonthly.name,
    price: billings.premiumMonthly.price,
    priceDescription: 'per month',
    features: ['Same as the yearly premium plan'],
    next: ['Monitor bookmakes for updates', 'Collaboration with friends'],
    button: {
      label: `Get ${billings.premiumMonthly.name}`,
      to: '/pricing',
    },
  },
  // {
  //   id: 3,
  //   favourite: false,
  //   title: billings.lifetime.name,
  //   price: billings.lifetime.price,
  //   subtitle: 'Limited seats ✨',
  //   priceDescription: 'one time only',
  //   priceClarification: '',
  //   features: ['Everything on the Premium plan', 'Access to all upcoming features', 'Premium support'],
  //   next: ['Pocket & Twitter integration', 'Save tabs set', 'Text highlight'],
  //   button: {
  //     label: `Get ${billings.lifetime.name}`,
  //     to: '/pricing',
  //   },
  // },
]

const styles = {
  container: (columnWidth, gridColumnGap, gridRowGap, isDark, paddingContainer, cardBackground) => css`
    margin: 0 auto -20px auto;
    padding: ${paddingContainer};
    display: grid;
    grid-template-columns: repeat(${columnWidth}, minmax(0, 310px));
    grid-column-gap: ${gridColumnGap};
    grid-row-gap: ${gridRowGap};
    width: 100%;
    justify-content: center;
    transition: all 600ms;
    border-radius: 10px;
    ${isDark &&
    !cardBackground &&
    css`
      background-color: ${theme.colors.custom.darkBlue};
    `}
  `,
  card: (colorModeInverse, favourite, cardBackground, isChrome) => css`
    border-radius: 10px;
    padding: 30px 10px;
    position: relative;
    overflow: hidden;

    ${colorModeInverse === 'dark' &&
    css`
      box-shadow: 0 0 0 1px ${rgba(theme.colors.background[colorModeInverse], 0.12)};
    `}

    ${cardBackground &&
    css`
      background-color: ${colorModeInverse === 'light' ? theme.colors.custom.darkBlue : 'transparent'};
      border-radius: 10px;
    `}

    ${favourite &&
    css`
      background: rgba(255, 255, 255, 0.06);
      backdrop-filter: blur(86px);
      border-radius: ${cardBackground || colorModeInverse === 'dark' ? '10px' : '26px'};
      border: none;
      box-shadow: none;
      ${!isChrome &&
      css`
        background: ${colorModeInverse === 'dark' ? '#f0e5ff' : '#473b62'};
      `}
    `}
  `,
  title: favourite => css`
    text-align: left;
    margin-left: 16px;
    font-weight: 700;
    ${favourite &&
    css`
      background-image: linear-gradient(90deg, ${theme.colors.custom.purple} 32.81%, ${theme.colors.custom.pink} 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `}
  `,
  subtitle: css`
    text-align: left;
    font-weight: 500;
    margin-bottom: 10px;
    position: relative;
    bottom: 8px;
    left: 3px;
    color: ${theme.colors.custom.green};
  `,
  price: css`
    margin-right: 5px;
  `,
  priceDescription: isDark => css`
    color: ${isDark ? theme.colors.text.accentedGray : theme.colors.accented.base};
  `,
  clarificationWrapper: css`
    margin-top: -5px;
  `,
  clarificationWrapperHeight: ['auto', 'auto', 'auto', '26px'],
  priceClarification: css`
    color: ${theme.colors.custom.green};
    font-size: 14px;
    margin-left: 3px;
  `,
  lines: css`
    height: 1px;
    width: 100%;
    background-color: ${theme.colors.primary.base};
  `,
  featuresWrapper: featuresWrapperMinHeight => css`
    padding: 10px 14px;
    min-height: ${featuresWrapperMinHeight};
  `,
  feature: css`
    margin-left: 10px;
  `,
  soon: css`
    background-color: ${theme.colors.accented.base};
    color: ${theme.colors.white};
    font-size: 9px;
    border-radius: 4px;
    font-weight: 500;
    padding: 1px 3px 2px 3px;
    position: relative;
    bottom: 2px;
  `,
  featureFontSize: ['14px', '15px'],
  titleFontSize: ['22px', '28px'],
  priceFontSize: ['30px', '36px'],
  priceDescriptionFontSize: ['17px', '18px'],
  priceDescriptionPaddingTop: ['7px', '12px'],
  priceOff: css`
    position: absolute;
    top: -85px;
    left: 61%;
    color: ${theme.colors.custom.green};
    font-size: 20px;
    span {
      position: relative;
      top: 15px;
    }
  `,
  checkContainer: isDark => css`
    width: 16px;
    height: 16px;
    background-color: ${isDark ? '#33333c' : theme.colors.accented.base};
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  `,
  featureItem: css`
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  `,
  backdropPurple: (favourite, isChrome) => css`
    background: radial-gradient(50% 1062% at 50% 50%, rgba(134, 97, 255, 0.64) 32.81%, rgba(248, 159, 255, 0.64) 100%);
    filter: blur(140px);
    border-radius: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: 0;
    height: 80%;
    width: 50%;
    transform: translate(-50%, -50%);

    ${!favourite &&
    css`
      display: none;
    `}

    ${!isChrome &&
    css`
      background: none;
    `}
  `,
}

function PriceCard({ colorModeInverse, featuresWrapperMinHeight, buttonSize, cardBackground, isChrome, ...item }) {
  const itemButtonProps = { ...item.button, to: item.button.onClick ? null : item.button.to }

  const buttonProps =
    item.favourite || item?.button?.colorScheme === 'red'
      ? {
          colorScheme: 'accentedButton',
          lightMode: true,
          ...itemButtonProps,
        }
      : {
          color: '',
          colorScheme: 'accentedButton',
          variant: 'outline',
          borderColor: theme.colors.accented.base,
          borderWidth: '2px',
          ...itemButtonProps,
        }

  return (
    <Div flex column>
      <Div css={styles.card(colorModeInverse, item.favourite, cardBackground, isChrome)}>
        <Div ml="13px" mb="10px">
          <Text css={styles.price} fontWeight="bold" fontSize={styles.priceFontSize}>
            ${item.price}
          </Text>
          <Text
            css={styles.priceDescription(colorModeInverse === 'light')}
            fontSize={styles.priceDescriptionFontSize}
            pt={styles.priceDescriptionPaddingTop}
          >
            {item.priceDescription}
          </Text>
        </Div>
        <Div>
          <Text css={styles.title(item.favourite)} fontWeight="medium" fontSize={styles.titleFontSize} block>
            {item.title}
          </Text>
        </Div>
        <Div flex column css={styles.featuresWrapper(featuresWrapperMinHeight)}>
          {item.subtitle && (
            <Text css={styles.subtitle} fontWeight="200" fontSize={styles.priceDescriptionFontSize} block>
              {item.subtitle}
            </Text>
          )}
          {item.features.map(feature => (
            <div key={feature} css={styles.featureItem}>
              <span css={styles.checkContainer(colorModeInverse === 'light')}>
                <Check boxSize={4} color="#fff" />
              </span>
              <Text
                css={styles.feature}
                fontSize={styles.featureFontSize}
                color={colorModeInverse === 'light' ? theme.colors.text.accentedGray : 'inherit'}
              >
                {feature}
              </Text>
            </div>
          ))}
          <VerticalSpace />
          {item.next.map(next => (
            <div key={next}>
              <VerticalSpace space={0.2} />
              <span css={styles.soon}>soon</span>
              <Text css={styles.feature} fontSize={styles.featureFontSize}>
                {next}
              </Text>
            </div>
          ))}
        </Div>
        <VerticalSpace />
        <VerticalSpace space={2} />
        <Div center>
          <Button size={buttonSize} aria-label="Start for free" width="80%" fontSize="15px" {...buttonProps}>
            {item.button.label}
          </Button>
        </Div>
        <VerticalSpace space={0.4} />
        <div css={styles.backdropPurple(item.favourite, isChrome)} />
      </Div>
    </Div>
  )
}

function PricingList({ buttonsProps }) {
  const columnWidth = useResponsive(1, 1, 1, 3)
  const featuresWrapperMinHeight = useResponsive('auto', '290px', '290px', '410px')
  const gridColumnGap = useResponsive('1.5em', '2em', '2em', '1.5em')
  const gridRowGap = useResponsive('1.5em', '2em')
  const paddingContainer = useResponsive('10px', '0px', '10px', '30px 20px')
  const buttonSize = useResponsive('sm', 'md')
  const cardBackground = useResponsive(true, true, true, false)
  const { colorMode } = useColorMode()
  const colorModeInverse = (colorMode === 'dark' && 'light') || (colorMode === 'light' && 'dark')
  const isChrome = useIsChrome()
  const bottomSpace = useResponsive(5, 10, 15)
  const cards = useMemo(() => {
    if (buttonsProps?.length) {
      return list.map((card, i) => ({ ...card, button: { ...card.button, ...buttonsProps[i] } }))
    }
    return list
  }, [buttonsProps])

  return (
    <Box position="relative">
      <VerticalSpace space={4.5} />
      <Box
        css={styles.container(
          columnWidth,
          gridColumnGap,
          gridRowGap,
          colorMode === 'dark',
          paddingContainer,
          cardBackground
        )}
      >
        {cards.map(item => (
          <PriceCard
            key={item.id}
            {...item}
            colorModeInverse={colorModeInverse}
            featuresWrapperMinHeight={featuresWrapperMinHeight}
            buttonSize={buttonSize}
            cardBackground={cardBackground}
            isChrome={isChrome}
          />
        ))}
      </Box>
      <VerticalSpace space={bottomSpace} />
    </Box>
  )
}

export default PricingList
